<template>
  <!-- TODO redo -->
  <div class="template">
    <list-container
      placeholder="模板名称/设备类型"
      :customReferenceCountKey="'deviceNumber'"
      :searchText.sync="searchText"
      :multipleSelection="multipleSelection"
      :pagination="pagination"
      @add="addData"
      @multipleDel="multipleDel"
      @fetch="loadData"
    >
      <template v-slot:grid>
        <grid isAdd isUpdate isDel :Gridtype="4" :GridData="data" @add="addData" @intoGrid="view" @editRow="edit" @deleteRow="del" />
      </template>
      <template v-slot:table>
        <base-table :data="data" :columns="columns" :operations="operations" :multipleSelection.sync="multipleSelection" />
      </template>
    </list-container>
    <Drawer title="引用列表" AlertTitle="该模板被以下设备所引用，无法删除" staticIcon :drawer="drawer" :isBtnGroup="false" :DeviceData="drawerData" @closeDrawer="drawer = false" />
  </div>
</template>

<script>
import listContainer from '@/components/list-container/list-container';
import grid from '@/components/grid/index.vue';
import baseTable from '@/components/table/base-table/base-table';
import Drawer from '@/components/drawer/index.vue';
import { table } from './mixin';
import { TABLE } from '/app.config';
export default {
  name: 'device_template',
  mixins: [table],
  data() {
    return {
      drawer: false,
      drawerData: [],
      data: [],
      searchText: '',
      multipleSelection: [],
      pagination: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      }
    };
  },
  activated() {
    this.loadData();
  },
  methods: {
    // 加载数据
    async loadData(param = {}) {
      const params = {
        keyword: this.searchText,
        currentPage: param.currentPage || this.pagination.currentPage,
        currentPageSize: param.currentPageSize || this.pagination.pageSize
      };

      const { items, total, currentPage, currentPageSize } = await this.$apis.deviceTemplate.list(params);
      this.data = items;

      this.pagination.total = total;
      this.pagination.pageSize = currentPageSize;
      this.pagination.currentPage = currentPage;
    },
    // 添加
    addData() {
      this.$router.push({ name: 'templateAdd' });
    },
    // 查看详情
    view({ templateId }) {
      this.$router.push({
        name: 'templateDetail',
        params: {
          id: templateId
        }
      });
    },
    // 修改
    edit({ templateId }) {
      this.$router.push({ name: 'templateEdit', query: { type: 'edit', templateId } });
    },
    // 删除
    async del({ deviceNumber, templateId, templateIds }) {
      // 如果该模板有被引用，则显示引用列表
      if (deviceNumber) {
        this.drawer = true;
        this.drawerData = await this.$apis.deviceTemplate.getDeviceList({ templateId });
        return;
      }

      this.$confirm('此操作将永久删除模板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { fail } = await this.$apis.deviceTemplate.del({ templateIds: templateId || templateIds });
          if (!fail) {
            this.loadData();
          }
        })
        .catch(() => {
          // do something...
        });
    },
    // 多选删除
    multipleDel() {
      const templateIds = this.multipleSelection.map(template => template.templateId);
      this.del({ templateIds: templateIds.join() });
    }
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  components: {
    listContainer,
    grid,
    baseTable,
    Drawer
  }
};
</script>
